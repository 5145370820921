import React from 'react';
import { Divider, Drawer, List, IconButton, Toolbar } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import KingBedIcon from '@mui/icons-material/KingBed';
import useIsGranted from '@pangaea/shared/src/security/useIsGranted';
import FlightIcon from '@mui/icons-material/Flight';
import ArticleIcon from '@mui/icons-material/Article';
import DestinationTypeCodes from '@pangaea/shared/src/features/destinationType/DestinationTypeCodes';
import SideBarListItemLink from './SideBarListItemLink';
import Roles from '../../security/Roles';

interface SideBarProps {
    open: boolean;
    toggleDrawer: () => void;
}

const SideBar = ({ open, toggleDrawer }: SideBarProps) => {
    const isAdmin = useIsGranted(Roles.ADMIN);
    const isCommercial = useIsGranted(Roles.COMMERCIAL);

    return (
        <Drawer
            variant="permanent"
            sx={{
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    width: 240,
                    minHeight: '100vh',
                    transition: (theme) =>
                        theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    boxSizing: 'border-box',
                    ...(!open && {
                        overflowX: 'hidden',
                        transition: (theme) =>
                            theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                    theme.transitions.duration.leavingScreen,
                            }),
                        width: (theme) => ({
                            xs: theme.spacing(7),
                            sm: theme.spacing(9),
                        }),
                    }),
                },
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer} data-testid="sideBarButton">
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="div">
                {isCommercial && (
                    <>
                        <SideBarListItemLink
                            to="/contrats"
                            root
                            icon={<ArticleIcon />}
                        >
                            {' '}
                            Mes contrats
                        </SideBarListItemLink>
                        <SideBarListItemLink
                            to={`/types/${DestinationTypeCodes.PSV}/contrats`}
                        >
                            Sport
                        </SideBarListItemLink>
                        <SideBarListItemLink
                            to={`/types/${DestinationTypeCodes.PV}/contrats`}
                        >
                            Loisir
                        </SideBarListItemLink>
                        <SideBarListItemLink
                            root
                            to="/destinations"
                            icon={<FlightIcon />}
                        >
                            Pilotage
                        </SideBarListItemLink>
                    </>
                )}
                {isAdmin && (
                    <>
                        <SideBarListItemLink
                            root
                            to="/utilisateurs"
                            icon={<PersonIcon />}
                        >
                            Utilisateurs
                        </SideBarListItemLink>
                        <SideBarListItemLink
                            root
                            to="/types-de-chambre"
                            icon={<KingBedIcon />}
                        >
                            Types de chambre
                        </SideBarListItemLink>
                    </>
                )}
            </List>
        </Drawer>
    );
};

export default SideBar;
