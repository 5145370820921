import React from 'react';
import AlertProvider from '@pangaea/shared/src/tools/alert/AlertProvider';
import { LocalizationProvider } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import { ApolloProvider } from '@apollo/client';
import BrowserRouter from '@pangaea/shared/src/router/BrowserRouter';
import PermissionsProvider from '@pangaea/shared/src/security/PermissionsProvider';
import PANGAEA_ROLES_HIERARCHY from '@pangaea/shared/src/security/pangaea-roles-hierarchy';
import {
    getRoles,
    isAuthenticated as apolloIsAuthenticated,
} from '@pangaea/shared/src/apollo/auth-tokens';
import DateFnsUtilsFR from '@pangaea/shared/src/date-fns/DateFnsUtilsFR';
import { ThemeProvider } from '@mui/material';
import initApollo from './apollo/initApollo';
import theme from './mui/theme';
import PageConfigProvider from './layout/PageConfigProvider';
import Pages from './pages/Pages';
import CONFIGS from './configs';

const apolloClient = initApollo();
const getUserRoles = () => getRoles(CONFIGS.localStorageKeys) || [];
const isAuthenticated = () => apolloIsAuthenticated(CONFIGS.localStorageKeys);

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider
                dateAdapter={DateFnsUtilsFR}
                locale={frLocale}
            >
                <ApolloProvider client={apolloClient}>
                    <PermissionsProvider
                        getUserRoles={getUserRoles}
                        rolesHierarchy={PANGAEA_ROLES_HIERARCHY}
                        isAuthenticated={isAuthenticated}
                        loginRoute="/auth/connexion"
                    >
                        <AlertProvider>
                            <BrowserRouter>
                                <PageConfigProvider>
                                    <Pages />
                                </PageConfigProvider>
                            </BrowserRouter>
                        </AlertProvider>
                    </PermissionsProvider>
                </ApolloProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default App;
