import DateAdapter from '@mui/lab/AdapterDateFns';

class DateFnsUtilsFR extends DateAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getFormatHelperText = (format: string) => {
        return 'jj/mm/aaaa';
    };
}

export default DateFnsUtilsFR;
