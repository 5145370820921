import { RolesHierarchy } from './isGranted';

// à delete si on réutilise security dans un autre projet

const PANGAEA_ROLES_HIERARCHY: RolesHierarchy = {
    ROLE_BO: ['ROLE_USER'],
    ROLE_INTERN: ['ROLE_BO'],
    ROLE_COMMERCIAL: ['ROLE_INTERN'],
    ROLE_TECHNICIAN: ['ROLE_COMMERCIAL', 'ROLE_BONUS'],
    ROLE_TECHNICAL_MANAGER: ['ROLE_TECHNICIAN'],
    ROLE_LOGISTICS_MANAGER: ['ROLE_TECHNICAL_MANAGER'],
    ROLE_ADMIN: ['ROLE_LOGISTICS_MANAGER'],
};

export default PANGAEA_ROLES_HIERARCHY;
