import JwtPayload from './JwtPayload';
import parseJwtPayload from './parseJwtPayload';

const isJwtExpired = (jwt: string) => {
    const jwtPayload: JwtPayload = parseJwtPayload(jwt);

    if (!jwtPayload.exp) {
        throw Error('Missing key "exp" in jwt payload.');
    }

    const currentDate: number = Math.round(Date.now() / 1000); // en secondes

    // on expire le token 10 secondes avant la vraie date pour que la requête ai le temps d'atteindre le serveur
    return jwtPayload.exp - 10 < currentDate;
};

export default isJwtExpired;
