import React from 'react';
import { Box } from '@mui/system';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { usePageConfig } from './PageConfigProvider';
import DisconnectIconButton from '../ui/Buttons/DisconnectIconButton';

interface HeaderProps {
    open?: boolean;
    toggleDrawer?: any;
}

const Header = ({ open, toggleDrawer }: HeaderProps) => {
    const { title } = usePageConfig();
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="absolute"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    transition: (theme) =>
                        theme.transitions.create(['width', 'margin'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    ...(open && {
                        marginLeft: 240,
                        width: 'calc(100% - 240px)',
                        transition: (theme) =>
                            theme.transitions.create(['width', 'margin'], {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                    theme.transitions.duration.enteringScreen,
                            }),
                    }),
                }}
            >
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>
                    <DisconnectIconButton component={Link} to="/deconnexion" />
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Header;
