import { TokenRefreshLink } from 'apollo-link-token-refresh';
import LocalStorageKeys from './LocalStorageKeys';
import isJwtExpired from './isJwtExpired';

type PayloadHandler<JwtResponsePayloadType, ResponseType> = (
    tokenPayload: JwtResponsePayloadType
) => ResponseType;
export interface CreateTokenRefreshLinkConfigs<JwtResponsePayloadType> {
    refreshTokenGraphqlQuery: string;
    jwtFieldInResponse: string;
    handleRefreshTokenError: (error: Error) => void;
    extractToken?: PayloadHandler<JwtResponsePayloadType, string>;
    extractRoles?: PayloadHandler<JwtResponsePayloadType, string[]>;
}

const queryToken = (
    apiEntrypoint: string,
    refreshToken: string,
    graphqlQuery: string
) => {
    return fetch(`${apiEntrypoint}/graphql`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: graphqlQuery,
            variables: {
                refreshToken,
            },
        }),
    });
};

const defaultExtractToken = (tokenPayload: any) => {
    return tokenPayload;
};

/**
 * Link pour rafraichir le JWT de l'utilisateur connecté si nécessaire avant une requête.
 * Pour JwtResponsePayloadType c'est le type du contenu au niveau de la config jwtFieldInResponse dans la réponse qui est attendu, pas le type de la réponse complète.
 *
 * @param apiEntrypoint Url de l'api GRAPHQL sur laquelle faire la requête de rafraichissement du token. (configs.refreshTokenGraphqlQuery)
 * @param localStorageKeys Les clés à utiliser pour sauvegarder les données dans le localstorage.
 * @param configs
 * @returns TokenRefreshLink
 */
function createTokenRefreshLink<JwtResponsePayloadType = string>(
    apiEntrypoint: string,
    localStorageKeys: LocalStorageKeys,
    configs: CreateTokenRefreshLinkConfigs<JwtResponsePayloadType>
) {
    const extractToken = configs.extractToken || defaultExtractToken;

    return new TokenRefreshLink<JwtResponsePayloadType>({
        accessTokenField: configs.jwtFieldInResponse,
        isTokenValidOrUndefined: () => {
            const jwt: string | null = localStorage.getItem(
                localStorageKeys.authToken
            );

            return !jwt || !isJwtExpired(jwt);
        },
        fetchAccessToken: () => {
            const refreshToken: string | null = localStorage.getItem(
                localStorageKeys.refreshToken
            );

            return queryToken(
                apiEntrypoint,
                refreshToken || '',
                configs.refreshTokenGraphqlQuery
            );
        },
        handleFetch: (tokenPayload: JwtResponsePayloadType) => {
            localStorage.setItem(
                localStorageKeys.authToken,
                extractToken(tokenPayload)
            );
            if (configs.extractRoles) {
                localStorage.setItem(
                    localStorageKeys.roles,
                    JSON.stringify(configs.extractRoles(tokenPayload))
                );
            }
        },
        handleError: (err) => {
            configs.handleRefreshTokenError(err);
        },
    });
}

export default createTokenRefreshLink;
