export interface RolesHierarchy {
    [role: string]: string[];
}

const isGranted = (
    checkRole: string,
    userRoles: string[],
    hierarchy: RolesHierarchy | null = null
): boolean => {
    for (let ind = 0; ind < userRoles.length; ind += 1) {
        const userRole: string = userRoles[ind];

        if (checkRole === userRole) {
            return true;
        }

        if (hierarchy) {
            const hierarchyRoles: string[] | undefined = hierarchy[userRole];

            if (hierarchyRoles) {
                const isGrantedByHierarchy: boolean = isGranted(
                    checkRole,
                    hierarchyRoles,
                    hierarchy
                );
                if (isGrantedByHierarchy) {
                    return true;
                }
            }
        }
    }

    return false;
};

export default isGranted;
