import LocalStorageKeys from './LocalStorageKeys';

export const getAuthToken = (
    localStorageKeys: LocalStorageKeys
): string | null => {
    return localStorage.getItem(localStorageKeys.authToken);
};

export const isAuthenticated = (
    localStorageKeys: LocalStorageKeys
): boolean => {
    return Boolean(getAuthToken(localStorageKeys));
};

export const getRefreshToken = (
    localStorageKeys: LocalStorageKeys
): string | null => {
    return localStorage.getItem(localStorageKeys.refreshToken);
};

export const getRoles = (
    localStorageKeys: LocalStorageKeys
): string[] | null => {
    const roles: string | null = localStorage.getItem(localStorageKeys.roles);

    if (!roles) {
        return null;
    }

    return JSON.parse(roles);
};

export const setRoles = (
    localStorageKeys: LocalStorageKeys,
    roles: string[] | null
) => {
    if (roles) {
        localStorage.setItem(localStorageKeys.roles, JSON.stringify(roles));
    } else {
        localStorage.removeItem(localStorageKeys.roles);
    }
};

export const setAuthTokens = (
    localStorageKeys: LocalStorageKeys,
    token: string | null,
    refreshToken: string | null,
    roles: string[] | null
) => {
    if (
        !localStorageKeys.authToken ||
        !localStorageKeys.refreshToken ||
        !localStorageKeys.roles
    ) {
        throw Error(
            "La clé pour sauvegarder le token dans le localStorage n'est pas définie. Il faut avoir appellé la fonction initApollo avant de sauvegarder un token."
        );
    }

    if (token) {
        localStorage.setItem(localStorageKeys.authToken, token);
    } else {
        localStorage.removeItem(localStorageKeys.authToken);
    }

    if (refreshToken) {
        localStorage.setItem(localStorageKeys.refreshToken, refreshToken);
    } else {
        localStorage.removeItem(localStorageKeys.refreshToken);
    }

    if (roles) {
        localStorage.setItem(localStorageKeys.roles, JSON.stringify(roles));
    } else {
        localStorage.removeItem(localStorageKeys.roles);
    }
};

export const removeAuthTokens = (localStorageKeys: LocalStorageKeys) => {
    setAuthTokens(localStorageKeys, null, null, null);
};
