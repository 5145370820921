import { Theme, createTheme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { frFR } from '@mui/material/locale';

declare module '@mui/material/styles/createPalette' {
    interface TypeBackground {
        darker: string;
        dark: string;
        main: string;
        light: string;
        lighter: string;
    }
}

const baseTheme: Theme = createTheme(
    {
        typography: {
            h1: {
                fontSize: '3rem',
            },
            h2: {
                fontSize: '2rem',
            },
            h3: {
                fontSize: '1.75rem',
            },
            h4: {
                fontSize: '1.5rem',
            },
            h5: {
                fontSize: '1.25rem',
            },
            h6: {
                fontSize: '1.125rem',
            },
            body1: {
                fontSize: '1rem',
            },
        },
        palette: {
            primary: {
                main: '#ba2174',
                light: '#c74d8f',
                dark: '#821751',
                contrastText: '#fff',
            },
            background: {
                default: '#ffffff',
                paper: blueGrey[50],
                light: blueGrey[200],
                lighter: blueGrey[100],
                dark: blueGrey[500],
                darker: blueGrey[600],
            },
        },
    },

    frFR
);

const theme = createTheme(baseTheme, {
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    marginTop: baseTheme.spacing(2),
                    minWidth: '225px',
                },
            },
            defaultProps: {
                size: 'small',
                fullWidth: true,
            },
            '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                    display: 'none',
                    '-moz-appearance': 'textfield',
                },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&:nth-of-type(2n)': {
                        backgroundColor: baseTheme.palette.action.hover,
                        border: '1px solid ',
                        borderColor: baseTheme.palette.primary.main,
                        borderTop: '0',
                        borderLeft: '0',
                        borderRight: '0',
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderCollapse: 'unset',
                    borderRadius: 5,
                    overflow: 'hidden',
                    backgroundColor: baseTheme.palette.primary.main,
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderRadius: '0.25em 0.25em 0 0',
                    overflow: 'hidden',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    textAlign: 'center',
                    position: 'relative',
                    padding: 3,
                    '&::after': {
                        content: "''",
                        position: 'absolute',
                        width: '1px',
                        top: 0,
                        right: -1,
                        height: '100%',
                        // eslint-disable-next-line max-len
                        backgroundImage: `linear-gradient(180deg,white 20%,${baseTheme.palette.background.light} 20%,${baseTheme.palette.background.light} 80%, white 80%)`,
                        zIndex: '-1',
                    },
                    '& .MuiBox-root': {
                        margin: '0 auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    '&:last-child ': {
                        '& .MuiBox-root': {
                            margin: 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        '&::after': {
                            width: '0',
                        },
                    },
                },
                head: {
                    color: baseTheme.palette.primary.contrastText,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    flexShrink: 0,
                    width: 240,
                    '& .MuiDrawer-paper': {
                        width: 240,
                        backgroundColor: baseTheme.palette.background.paper,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 'medium',
                    paddingTop: 8,
                },
            },
        },
    },
});

export default theme;
