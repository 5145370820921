import React from 'react';
import { Navigate, To } from 'react-router';
import useIsGranted from './useIsGranted';

interface RequireRoleProps {
    role: string;
    children: React.ReactNode;
    redirectTo?: To;
}

/**
 * @see https://reactrouter.com/docs/en/v6/examples/auth
 */
const RequireRole = ({ role, children, redirectTo }: RequireRoleProps) => {
    const isGranted = useIsGranted(role);

    if (!isGranted) {
        return <Navigate to={redirectTo || '/'} replace />;
    }

    return <>{children}</>;
};

export default RequireRole;
