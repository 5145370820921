import { AlertColor } from '@mui/material';
import React, { useContext, useState, useCallback } from 'react';
import Alert from './Alert';
import AlertSnackBar from './AlertSnackBar';

export type SetAlert = (message: string, severity: AlertColor) => void;

const initialAlertCtxValues: SetAlert = () => {};

export const AlertCtx = React.createContext<SetAlert>(initialAlertCtxValues);

export const useAlert = () => useContext(AlertCtx);

interface AlertProviderProps {
    children?: React.ReactNode;
}

const AlertProvider = ({ children }: AlertProviderProps) => {
    const [alert, setAlert] = useState<Alert>();

    const setEasyAlert = useCallback(
        (message: string, severity: AlertColor = 'info') => {
            setAlert({ message, severity });
        },
        [setAlert]
    );

    return (
        <AlertCtx.Provider value={setEasyAlert}>
            <>
                <AlertSnackBar duration={10000} alert={alert} />
                {children}
            </>
        </AlertCtx.Provider>
    );
};

export default AlertProvider;
