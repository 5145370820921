import React, { useState, useLayoutEffect } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { BrowserRouterProps } from 'react-router-dom';

export const history = createBrowserHistory();

/**
 * BrowserRouter comme celui de react-router-dom mais sans la prop "window".
 * À la place on a un objet history global pour l'utiliser en dehors des composants.
 * @see https://stackoverflow.com/a/70000286
 */
const BrowserRouter = ({
    basename,
    children,
}: Exclude<BrowserRouterProps, 'window'>) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), []);

    return (
        <Router
            basename={basename}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        >
            {children}
        </Router>
    );
};

export default BrowserRouter;
