import React from 'react';
import { Typography } from '@mui/material';
import { PageConfig, usePageConfig } from '../layout/PageConfigProvider';

const pageConfig: PageConfig = { title: 'Accueil' };
const Home = () => {
    usePageConfig(pageConfig);

    return (
        <>
            <Typography variant="h1" component="div">
                BO Pangaea
            </Typography>
        </>
    );
};

export default Home;
