import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router';
import RequireAnonymous from '@pangaea/shared/src/security/RequireAnonymous';
import RequireAuth from '@pangaea/shared/src/security/RequireAuth';
import RequireRole from '@pangaea/shared/src/security/RequireRole';
import Home from './Home';
import Layout from '../layout/Layout';
import Logout from './Logout';
import Roles from '../security/Roles';

const AuthPages = lazy(() => import('./auth/AuthPages'));

const PageLoader = () => <></>;

const AdminsPages = lazy(() => import('./admins/AdminsPages'));
const RoomTypesPages = lazy(() => import('./roomTypes/RoomTypesPages'));
const DestinationsPages = lazy(
    () => import('./destinations/DestinationsPages')
);
const ContractsPages = lazy(() => import('./contracts/ContractsPages'));
const ContractFieldsPages = lazy(
    () => import('./contractFields/ContractFieldsPages')
);

const Pages = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                <Route
                    path="/auth/*"
                    element={
                        <RequireAnonymous>
                            <AuthPages />
                        </RequireAnonymous>
                    }
                />
                <Route
                    path="/deconnexion"
                    element={
                        <RequireAuth>
                            <Logout />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Layout />
                        </RequireAuth>
                    }
                >
                    <Route index element={<Home />} />
                    <Route
                        path="/utilisateurs/*"
                        element={
                            <RequireRole role={Roles.ADMIN}>
                                <AdminsPages />
                            </RequireRole>
                        }
                    />
                    <Route
                        path="/types-de-chambre/*"
                        element={
                            <RequireRole role={Roles.ADMIN}>
                                <RoomTypesPages />
                            </RequireRole>
                        }
                    />
                    <Route
                        path="/destinations/*"
                        element={
                            <RequireRole role={Roles.TECHNICAL_MANAGER}>
                                <DestinationsPages />
                            </RequireRole>
                        }
                    />
                    <Route
                        path="/contrats/*"
                        element={
                            <RequireRole role={Roles.TECHNICAL_MANAGER}>
                                <ContractsPages />
                            </RequireRole>
                        }
                    />
                    <Route
                        path="/types/:type/contrats/*"
                        element={
                            <RequireRole role={Roles.TECHNICAL_MANAGER}>
                                <ContractsPages />
                            </RequireRole>
                        }
                    />
                    <Route
                        path="/champs/*"
                        element={
                            <RequireRole role={Roles.ADMIN}>
                                <ContractFieldsPages />
                            </RequireRole>
                        }
                    />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default Pages;
