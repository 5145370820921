import React from 'react';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router';

interface RootLink {
    /**
     * True si le lien se trouve à la racine de la sidebar. Sinon c'est un sous menu.
     */
    root?: boolean;
    icon?: React.ReactNode;
}

type SideBarListItemLinkProps = RootLink & Pick<LinkProps, 'to' | 'children'>;

export const getTypoSx = (match: boolean) => ({
    fontWeight: match ? 'bold' : 'normal',
});

const SideBarListItemLink = ({
    root,
    children,
    to,
    icon,
}: SideBarListItemLinkProps) => {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });

    return (
        <ListItemButton component={Link} to={to} sx={!root ? { pl: 4 } : {}}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                disableTypography
                primary={
                    <Typography component="span" sx={getTypoSx(!!match)}>
                        {children}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

export default SideBarListItemLink;
