enum DestinationTypeCodes {
    /**
     * Destination de type loisir.
     */
    PV = 'PV',

    /**
     * Destination de type sport.
     */
    PSV = 'PSV',
}

export default DestinationTypeCodes;
