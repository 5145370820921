import React, { useContext, useEffect, useState } from 'react';

export interface PageConfig {
    title?: string;
}

const initialValue: PageConfig = { title: 'Pangaea' };

type PageConfigCtxValue = [
    PageConfig,
    React.Dispatch<React.SetStateAction<PageConfig>>
];

const PageConfigCtx = React.createContext<PageConfigCtxValue>([
    initialValue,
    () => {},
]);

/**
 * Permet de définir la configuration de la page à partir des composants enfants
 * Exemple:
 * - Définir une config de page statique :
 * const pageConfig: PageConfig = {title: "Accueil"};
 * const Home = () => {
 *     usePageConfig(pageConfig);
 *     \\...
 * }
 *
 * - Définir une config de page dynamique :
 * const User = ({name}) => {
 *     const pageConfig = useMemo(() => ({title: name}), [name]);
 *     usePageConfig(pageConfig);
 *     \\...
 * }
 *
 * - Utiliser les configs :
 * const Header = () => {
 *     const {title} = usePageConfig();
 *     \\...
 * }
 */
export const usePageConfig = (newConfig?: PageConfig) => {
    const [config, setConfig] = useContext(PageConfigCtx);
    useEffect(() => {
        if (newConfig) {
            setConfig(newConfig);
        }
    }, [newConfig, setConfig]);

    return config;
};

interface PageConfigProviderProps {
    children: React.ReactNode;
}

const PageConfigProvider = ({ children }: PageConfigProviderProps) => {
    const value = useState<PageConfig>(initialValue);

    return (
        <PageConfigCtx.Provider value={value}>
            {children}
        </PageConfigCtx.Provider>
    );
};

export default PageConfigProvider;
