import isGranted from './isGranted';
import { usePermissions } from './PermissionsProvider';

const useIsGranted = (checkRole: string) => {
    const { getUserRoles, rolesHierarchy } = usePermissions();

    return isGranted(checkRole, getUserRoles(), rolesHierarchy);
};

export default useIsGranted;
