import React from 'react';
import { IconButton, ButtonProps } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

// concernant React.ElementType c'est requis pour pouvoir passer une prop "component" à NewButton et avoir l'autocompletion et la validation des props en fonction du composant passé
// Exemple : <NewButton component={RouterLink} /> -> la prop "to" est requise car requise par RouterLink
// voir https://github.com/mui-org/material-ui/issues/15827#issuecomment-851966416

/**
 * Bouton pour faire une modification de quelque chose. Juste de l'UI, pas de comportement. On peut l'utiliser pour ouvrir une dialog ou faire un lien.
 */

// Un composant générique IconButton avec children à la place d'un icon prédéfini ??
const DisconnectIconButton = <C extends React.ElementType>(
    props: ButtonProps<C, { component?: C }>
) => {
    return (
        <IconButton color="inherit" {...props}>
            <LogoutIcon />
        </IconButton>
    );
};

export default DisconnectIconButton;
