const CONFIGS = {
    apiEntrypoint: process.env.REACT_APP_API_ENTRYPOINT || '',
    localStorageKeys: {
        authToken:
            process.env.REACT_APP_AUTH_TOKEN_LOCALSTORAGE_KEY ||
            'authToken3WBWfXqjAsda8E6C',
        refreshToken:
            process.env.REACT_APP_REFRESH_TOKEN_LOCALSTORAGE_KEY ||
            'refreshToken4tRYPKB26NxyqckR',
        roles: 'roles',
        currentUser: process.env.REACT_APP_USER_LOCALSTORAGE_KEY || 'user',
    },
};

export default CONFIGS;
