import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import createAuthorizationLink from './createAuthorizationLink';
import createTokenRefreshLink, {
    CreateTokenRefreshLinkConfigs,
} from './createTokenRefreshLink';
import LocalStorageKeys from './LocalStorageKeys';

function initApollo<RefreshTokenPayload>(
    apiEntrypoint: string,
    localStorageKeys: LocalStorageKeys,
    refreshTokenConfigs: CreateTokenRefreshLinkConfigs<RefreshTokenPayload> | null
) {
    const links: ApolloLink[] = [];

    if (refreshTokenConfigs) {
        links.push(
            createTokenRefreshLink<RefreshTokenPayload>(
                apiEntrypoint,
                localStorageKeys,
                refreshTokenConfigs
            )
        );
    }

    links.push(createAuthorizationLink(localStorageKeys));

    links.push(
        createUploadLink({
            uri: `${apiEntrypoint}/graphql`,
        })
    );

    return new ApolloClient({
        cache: new InMemoryCache(),
        link: from(links),
    });
}

export default initApollo;
