enum Roles {
    USER = 'ROLE_USER',
    BO = 'ROLE_BO',
    INTERN = 'ROLE_INTERN',
    COMMERCIAL = 'ROLE_COMMERCIAL',
    TECHNICIAN = 'ROLE_TECHNICIAN',
    TECHNICAL_MANAGER = 'ROLE_TECHNICAL_MANAGER',
    LOGISTICS_MANAGER = 'ROLE_LOGISTICS_MANAGER',
    ADMIN = 'ROLE_ADMIN',
}

export default Roles;
