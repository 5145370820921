import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideBar from './SideBar/SideBar';
import Header from './Header';

const Layout = () => {
    const [open, setOpen] = React.useState(true);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar open={open} toggleDrawer={toggleDrawer} />
            <Container sx={{ pt: 10, pb: 4 }}>
                <Header open={open} toggleDrawer={toggleDrawer} />
                <Outlet />
            </Container>
        </Box>
    );
};

export default Layout;
