import React from 'react';
import { Navigate } from 'react-router';
import useIsAuthenticated from './useIsAuthenticated';

interface RequireAnonymousProps {
    children: React.ReactNode;
}

/**
 * @see https://reactrouter.com/docs/en/v6/examples/auth
 */
const RequireAnonymous = ({ children }: RequireAnonymousProps) => {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default RequireAnonymous;
