import sharedInitApollo from '@pangaea/shared/src/apollo/initApollo';
import { history } from '@pangaea/shared/src/router';
import CONFIGS from '../configs';
import AdminResponse from '../features/admin/AdminResponse.model';

interface RefreshTokenAdminResponse {
    admin: AdminResponse;
}

const initApollo = () => {
    return sharedInitApollo<RefreshTokenAdminResponse>(
        CONFIGS.apiEntrypoint,
        CONFIGS.localStorageKeys,
        {
            refreshTokenGraphqlQuery: `
                mutation RefreshToken($refreshToken: String!) {
                    refreshTokenAdmin(input: {refreshToken: $refreshToken}) {
                        admin {
                            token
                            roles
                        }
                    }
                }
            `,
            jwtFieldInResponse: 'refreshTokenAdmin',
            handleRefreshTokenError: () => history.push('/deconnexion'),
            extractToken: (payload) => payload.admin.token!,
            extractRoles: (payload) => payload.admin.roles!,
        }
    );
};

export default initApollo;
