import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { usePermissions } from './PermissionsProvider';
import useIsAuthenticated from './useIsAuthenticated';

interface RequireAuthProps {
    children: React.ReactNode;
}

/**
 * @see https://reactrouter.com/docs/en/v6/examples/auth
 */
const RequireAuth = ({ children }: RequireAuthProps) => {
    const { loginRoute } = usePermissions();
    const isAuthenticated = useIsAuthenticated();
    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to={loginRoute} state={{ from: location }} replace />;
    }

    return <>{children}</>;
};

export default RequireAuth;
